import { Layout } from "@/components/Layout";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { createBrowserSupabaseClient } from "@supabase/auth-helpers-nextjs";
import { Session, SessionContextProvider } from "@supabase/auth-helpers-react";
import { Analytics } from "@vercel/analytics/react";
import type { AppProps } from "next/app";
import { useState } from "react";

import dynamic from "next/dynamic";
import Head from "next/head";

const CrispWithNoSSR = dynamic(() => import("../components/CrispChat"), {
  ssr: false,
});

const theme = extendTheme({
  colors: {
    brand: {
      50: "#f0e4ff",
      100: "#cbb2ff",
      200: "#a480ff",
      300: "#7a4dff",
      400: "#641bfe",
      500: "#5a01e5",
      600: "#5200b3",
      700: "#430081",
      800: "#2d004f",
      900: "#14001f",
    },
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
});

export default function App({
  Component,
  pageProps,
}: AppProps<{
  initialSession: Session;
}>) {
  const [supabase] = useState(() => createBrowserSupabaseClient());

  return (
    <>
      <SessionContextProvider
        supabaseClient={supabase}
        initialSession={pageProps.initialSession}
      >
        <ChakraProvider theme={theme}>
          <Layout />
          <Head>
            <title key="title">* Brevito *</title>
          </Head>
          <Component {...pageProps} />
          <CrispWithNoSSR />
        </ChakraProvider>
      </SessionContextProvider>
      <Analytics />
    </>
  );
}
