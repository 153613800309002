import { Box, Flex, Stack, Tag, Text } from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import useSWR from "swr";

import { FiMenu, FiX } from "react-icons/fi";

export function Logo(props: any) {
  return (
    <Box {...props}>
      <Text fontSize="xl" size={"5vh"} fontWeight="bold">
        Brevito
      </Text>
    </Box>
  );
}

export function MenuToggle({ toggle, isOpen }: any) {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <FiX /> : <FiMenu size={"5vh"} />}
    </Box>
  );
}

export function MenuItem({ children, isLast, to = "/", ...rest }: any) {
  return (
    <Link href={to}>
      <Text display="block" {...rest}>
        {children}
      </Text>
    </Link>
  );
}

interface StatsResponse {
  n_files: number;
  n_questions: number;
}

export async function filesFetcher(url: string): Promise<StatsResponse> {
  const data = await fetch(url);
  const res = await data.json();
  return res;
}

export function MenuLinks({ isOpen, toggle }: any) {
  const user = useUser();
  const router = useRouter();
  const supabaseClient = useSupabaseClient();

  const { data, error, isLoading } = useSWR(
    user ? "/api/duracion" : null,
    filesFetcher,
    {
      refreshInterval: 1000 * 60 * 1,
    }
  );

  if (user) {
    Sentry.setUser({
      id: user.id,
      email: user.email,
    });
  }

  async function handleSignout() {
    await supabaseClient.auth.signOut();
    router.push("/");
    return <></>;
  }

  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={8}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
        mb={5}
        onClick={toggle}
      >
        {user ? (
          <>
            {data && (
              <>
                <Tag colorScheme={data.n_files > 1 ? "green" : "red"}>
                  {data?.n_files} files
                </Tag>
                <Tag colorScheme={data.n_questions > 1 ? "green" : "red"}>
                  {data?.n_questions} questions
                </Tag>
              </>
            )}

            <MenuItem to="/home"> home </MenuItem>
            <MenuItem to="/atm"> buy credits </MenuItem>
            <MenuItem to="/" onClick={handleSignout}>
              signout
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem to="/">home</MenuItem>
            <MenuItem to="/login">login 🤗</MenuItem>
          </>
        )}
      </Stack>
    </Box>
  );
}

export function NavBarContainer({ children, ...props }: any) {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      px="5vw"
      minH="10vh"
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["black", "black", "black", "black"]}
      border="0.1px solid grey"
      {...props}
    >
      {children}
    </Flex>
  );
}

export function Layout(props: any) {
  const [isOpen, setIsOpen] = useState(false);
  function toggle() {
    setIsOpen(!isOpen);
  }
  return (
    <NavBarContainer {...props}>
      <Logo w="100px" color={["blackalpha", "black", "black", "black"]} />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} toggle={toggle} />
    </NavBarContainer>
  );
}
